import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { LanguageService } from './language.service';
import { UserService } from './user.service';
import { GlobalConfigService } from './global-config.service';
import StorageHelper from '../helpers/Storage.helper';

@Injectable()
export class AppInitializer {
  // prettier-ignore
  constructor(
    private languageService: LanguageService,
    private userService: UserService,
    private globalConfigService: GlobalConfigService
  ) {}

  initialize(): Observable<any> {
    StorageHelper.clearLanguages();
    return this.languageService
      .initializeLocalTranslations()
      .pipe(
        mergeMap(() =>
          this.globalConfigService
            .initializeGlobalConfig()
            .pipe(mergeMap(() => this.userService.getUserInfo().pipe(mergeMap(() => this.languageService.refreshLanguages()))))
        )
      );
  }
}
