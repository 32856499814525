import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { GlobalConfigService } from './_core/services/global-config.service';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsService } from './_core/api/google-analytics.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { EntityDetailsRoutes, EntityDetailsTabRoutes } from './_core/constants/Global/AnalyticsTracking';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  favIcon: HTMLLinkElement;

  // prettier-ignore
  constructor(
    private titleService: Title,
    private globalConfigService: GlobalConfigService,
    private analyticsService: GoogleAnalyticsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.favIcon = this.document.querySelector('#favicon');
    this.setEnvironmentConfiguration();
    this.setColorTheme();
  }

  ngOnInit(): void {
    this.computePageTitle();
    if (this.globalConfigService.globalConfig.enableGoogleAnalytics && this.globalConfigService.globalConfig.googleAnalyticsTrackingCode.length > 0) {
      this.analyticsService.initialize();
    }
  }

  setEnvironmentConfiguration(): void {
    this.favIcon.href = environment.favicon;
  }

  setColorTheme(): void {
    const colors = [
      { name: 'auth-primary', value: `#${this.globalConfigService.globalConfig.authButtonBackgroundColor.hex}` },
      { name: 'auth-btn-text', value: `#${this.globalConfigService.globalConfig.authButtonTextColor.hex}` },
      { name: 'auth-secondary', value: `#${this.globalConfigService.globalConfig.authTextColor.hex}` },
    ];
    colors.forEach((color) => this.document.documentElement.style.setProperty(`--${color.name}`, color.value));
  }

  computePageTitle(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          if (!child) {
            return this.activatedRoute.snapshot.data.title || this.globalConfigService.globalConfig.applicationTitle;
          }

          while (child.firstChild) {
            child = child.firstChild;
          }

          return child.snapshot.data.title ? child.snapshot.data.title : this.globalConfigService.globalConfig.applicationTitle;
        })
      )
      .subscribe((title) => {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            const detailsRoute = EntityDetailsRoutes.find((route) => event.urlAfterRedirects.indexOf(route) !== -1);
            const detailsTabRoute = EntityDetailsTabRoutes.find((tabRoute) => event.urlAfterRedirects.indexOf(tabRoute) !== -1);
            if (!(detailsRoute && detailsTabRoute)) {
              this.titleService.setTitle(title);
            }
          }
        });
      });
  }
}
