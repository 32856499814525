import { Injectable } from '@angular/core';
import { Privilege } from '../constants/Privileges';
import { ReplaySubject } from 'rxjs';
import { GlobalConfigService } from './global-config.service';
import { UserDetails } from '../models/User';
import { Privileges } from '../models/Privileges';
import { Role, UserType } from '../constants/User';

@Injectable({
  providedIn: 'root',
})
export class PrivilegesService {
  private privilegesSource = new ReplaySubject<Privilege[]>(1);
  privileges: Privilege[];
  privileges$ = this.privilegesSource.asObservable();

  // prettier-ignore
  constructor(
    private globalConfigService: GlobalConfigService
  ) {}

  setPrivileges(data: UserDetails & Privileges): void {
    this.parsePrivileges(data);
    this.privilegesSource.next(data.privileges);
    this.privileges = data.privileges;
  }

  private parsePrivileges(data: UserDetails & Privileges): void {
    if (!this.globalConfigService.globalConfig.allowDonorsCreatePrivatePortfolios) {
      if (data.userType === UserType.DONOR && data.role === Role.USER) {
        data.privileges.splice(
          data.privileges.findIndex((item) => item === Privilege.PORTFOLIO_PRIVATE_SETUP),
          1
        );
        data.privileges.splice(
          data.privileges.findIndex((item) => item === Privilege.PORTFOLIO_LIST),
          1
        );
        data.privileges.splice(
          data.privileges.findIndex((item) => item === Privilege.PORTFOLIO_UPDATE),
          1
        );
      }
    }
  }
}
