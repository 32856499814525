import { AbstractControl } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';
import { FileItem } from 'ng2-file-upload';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { PageFilters, FilterOption } from 'src/app/_core/models/filters/Filters';
import { DOC_FORMATS, DOC_MIME_TYPES, IMG_FORMATS, IMG_MIME_TYPES, VIDEO_FORMATS, VIDEO_MIME_TYPES } from '../constants/FileType';
import { FilterSelected } from '../models/filters/Dashboard';
import { AddressComponentTypes } from '../constants/GooglePlaces';
import StorageHelper from './Storage.helper';
import { TokenData } from '../models/Auth';
import { UUIDName } from '../models/GenericObject';
import { Privilege } from '../constants/Privileges';
import { Tab } from '../models/Tab';
import Urls from '../constants/Urls';
import { RegexCodes } from '../constants/regexCodes';
import { Color } from '@angular-material-components/color-picker';
import { environment } from 'src/environments/environment';
import { EnvironmentName } from '../constants/EnvironmentConst';

export default class Utils {
  public static decodeToken(): TokenData {
    try {
      return jwt_decode.default(StorageHelper.getToken());
    } catch (Error) {
      return null;
    }
  }

  public static calculateFinancedPercent(amountRaised: number, totalAmount: number): number {
    return Math.floor((amountRaised * 100) / totalAmount);
  }

  public static compareArraysLength(array1: Array<any>, array2: Array<any>): boolean {
    return array1?.length === array2?.length;
  }

  public static setCurrencySymbol(select: AbstractControl | string, currencyList: Array<UUIDName> = []) {
    let selectedCurrency;

    if (currencyList.length) {
      selectedCurrency = currencyList.find(
        (currency) => currency.uuid === (select as AbstractControl).value?.uuid || currency.uuid === (select as AbstractControl).value
      );
    }
    const selectedCurrencySymbol = selectedCurrency ? selectedCurrency.name : select;

    switch (selectedCurrencySymbol) {
      case 'SGD':
        return ' S$ ';
      case 'HKD':
        return ' HK$ ';
      case 'USD':
        return ' $ ';
      case 'EUR':
        return ' € ';
      case 'CHF':
        return ' CHF ';
      case 'CAD':
        return ' CA$ ';
      case 'AUD':
        return ' A$ ';
      case 'GBP':
        return ' £ ';
      case 'NZD':
        return ' NZ$ ';
      case 'KSH':
        return ' KES ';
      case 'CZK':
        return ' Kč ';
      case 'PHP':
        return ' ₱ ';
      case 'JPY':
        return ' ¥ ';
      case 'MXN':
        return ' MX$ ';
      case 'INR':
        return ' ₹ ';
      case 'ZAR':
        return ' R ';
      case 'MYR':
        return ' RM ';
      case 'BRL':
        return ' R$ ';
      case 'THB':
        return ' ฿ ';
      default:
        if (typeof selectedCurrencySymbol !== 'string') return '';
        return ` ${selectedCurrencySymbol} `;
    }
  }

  public static extractField(array: Array<any>, fieldToSearch: string, valueToSearch: string): any {
    return array.find((item) => item[fieldToSearch] === valueToSearch);
  }

  public static extractFieldArray(array: Array<any>, field: string): string[] {
    return array.map((item) => item[field]);
  }

  public static extractFileExtension(fileName: string) {
    return fileName ? fileName.split('.').pop() : null;
  }

  public static localToUTCNoOffset(date: Date): number | null {
    return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).getTime() : null;
  }

  public static applyCurrencyMask(value: number) {
    return Intl.NumberFormat('en-us').format(value);
  }

  public static UTCtoLocalNoOffset(date: number): Date | null {
    if (date) {
      const localDate = new Date(date);
      return new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    }
    return null;
  }

  public static UTCtoLocalStringDateOnly(date: number): string | null {
    if (date) {
      return this.UTCtoLocalNoOffset(date).toLocaleString('en-US').split(',')[0];
    }
    return null;
  }

  public static UTCtoLocalStringDateAndTimeOnly(date: number): string | null {
    if (date) {
      const timeFormat: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString('en-US', timeFormat);
    }
    return null;
  }

  public static calculatePercent(total: number, raised: number): number {
    return total ? Math.floor((raised * 100) / total) : 0;
  }

  public static arrayToFormattedStr(array: Array<string>) {
    const typeStr = array.reduce((result: string, type: string) => {
      result = result + type + ', ';
      return result;
    }, '');
    return typeStr.substr(0, typeStr.length - 2);
  }

  public static changeIndexPositionInArray(array: any[], oldIndex: number): any[] {
    const leftSection = array.splice(0, oldIndex);
    return array.concat(leftSection);
  }

  public static getFileName(fileLongName: string) {
    return fileLongName?.split('/').pop();
  }

  public static checkFileType(fileName: string, mimeType: string, fileType: string): boolean {
    let mimeTypes: Array<string>;
    let fileFormats: Array<string>;
    switch (fileType) {
      case 'DOC':
        mimeTypes = DOC_MIME_TYPES;
        fileFormats = DOC_FORMATS;
        break;
      case 'VIDEO':
        mimeTypes = VIDEO_MIME_TYPES;
        fileFormats = VIDEO_FORMATS;
        break;
      case 'IMG':
        mimeTypes = IMG_MIME_TYPES;
        fileFormats = IMG_FORMATS;
        break;
    }
    return mimeType ? mimeTypes.indexOf(mimeType) > -1 : fileFormats.indexOf(Utils.extractFileExtension(fileName)) > -1;
  }

  public static computeLinksVisibility(links: Tab[], privileges: Privilege[]) {
    links.forEach((tab: Tab) => {
      if (tab.link == Urls.HOMEPAGE) {
        tab.visibility = true;
      } else {
        tab.visibility = privileges?.some((item: Privilege) => tab.privileges[item]);
      }
    });
  }

  public static sortBy(array: Array<any>, field: string, numberInString: boolean = false) {
    return array.sort((a: any, b: any) =>
      numberInString ? Number(a[field].split('-').shift()) - Number(b[field].split('-').shift()) : a[field].localeCompare(b[field])
    );
  }

  private static isDigit(char: string): boolean {
    return /[\d]/.test(char);
  }

  private static addWordToArray(word: string, words: string[], linkingWord: string): void {
    word.toLowerCase() === linkingWord ? words.push(word.toLowerCase()) : words.push(word);
  }

  public static variableToString(value: string, linkingWord: string): string {
    if (value) {
      const words: string[] = [];

      let previousCapitalLetterIndex = 0;
      let firstDigitIndex = 0;
      let isPreviousDigit = this.isDigit(value.charAt(0));

      value = value.charAt(0).toUpperCase() + value.slice(1, value.length);

      for (let i = 1; i < value.length; i++) {
        const char = value.charAt(i);
        if (this.isDigit(char)) {
          if (isPreviousDigit) {
            previousCapitalLetterIndex = i + 1;
          } else {
            const word = value.slice(previousCapitalLetterIndex, i);
            this.addWordToArray(word, words, linkingWord);
            firstDigitIndex = i;
          }
          isPreviousDigit = true;
        } else {
          if (isPreviousDigit) {
            const word = value.slice(firstDigitIndex, i);
            this.addWordToArray(word, words, linkingWord);
          } else {
            if (char === char.toUpperCase()) {
              const word = value.slice(previousCapitalLetterIndex, i);
              this.addWordToArray(word, words, linkingWord);
              previousCapitalLetterIndex = i;
            }
          }
          isPreviousDigit = false;
        }
      }
      const lastWord = value.slice(previousCapitalLetterIndex, value.length);
      this.addWordToArray(lastWord, words, linkingWord);
      return words.join(' ');
    }
    return null;
  }

  public static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static convertB64ToImage(b64string: string, fileToUpload: FileItem): File {
    const contentType = b64string.split('data:')[0];
    const data = b64string.split('base64,')[1];
    const blob: Blob = this.b64toBlob(data, contentType);
    return new File([blob], fileToUpload.file.name, { type: fileToUpload.file.type });
  }

  public static checkProperties(object: any) {
    for (const key in object) {
      if (object[key] !== null && object[key] !== '' && key !== 'position') {
        return false;
      }
    }
    return true;
  }

  public static navigate(url: string, location: string = '_blank') {
    let urlEditted = '';
    if (!/^http[s]?:\/\//.test(url)) {
      urlEditted += 'http://';
    }

    urlEditted += url;
    window.open(urlEditted, location);
  }

  public static setFilter(filterArray: FilterOption[], filterMenu: PageFilters<any>, filterSelected: FilterSelected): void {
    filterMenu.setDefaultSort();
    if (filterSelected.option === 'all') {
      filterArray.forEach((filter) => (filter.checked = true));
    } else {
      for (const filter of filterArray) {
        if (filter.value === filterSelected.option) {
          filter.checked = true;
          break;
        }
      }
    }
  }

  // NOTE: The order of items in addressComponentType matters
  public static getGooglePlacesData(addressComponents: AddressComponent[], addressComponentType: AddressComponentTypes[]) {
    let addressComponent;
    addressComponentType.every((component: AddressComponentTypes) => {
      addressComponent = addressComponents.find((address: AddressComponent) => {
        return address.types.indexOf(component) > -1;
      });
      return !addressComponent;
    });
    return addressComponent;
  }

  public static hasPrivilege(privilegeList: Privilege[], privilege: Privilege) {
    return privilegeList?.some((item: Privilege) => item === privilege);
  }

  public static removeDuplicatesInArrayOfObject(array, field) {
    const obj = {};
    const newArray = [];

    for (const item of array) {
      obj[item[field]] = item;
    }

    for (const key in obj) {
      newArray.push(obj[key]);
    }
    return newArray;
  }

  public static checkUrlOrEmailAndNavigate(link: string, location: string = '_blank') {
    const emailRgx = new RegExp(RegexCodes.email);
    if (emailRgx.test(link)) {
      window.open(`mailto:${link}`, location);
    } else {
      this.navigate(link, location);
    }
  }

  public static hasValue(prop: any): boolean {
    if (!prop) {
      return false;
    }
    if (typeof prop === 'object') {
      return !!Object.keys(prop).length && !!Object.keys(prop).find((child) => prop[child]);
    }
    return true;
  }

  public static cloneArray(array: any[]): any[] {
    return JSON.parse(JSON.stringify(array));
  }

  public static sortArray(array: any[], sortByProp: string, ascending = true, sortFalsyValuesProp?: string): any[] {
    if (!array?.length) return [];

    let sortedArray;
    switch (typeof array[0][sortByProp]) {
      case 'boolean':
        sortedArray = array.sort((a, b) => {
          if (!a[sortByProp] && b[sortByProp]) {
            return ascending ? 1 : -1;
          } else if (a[sortByProp] && !b[sortByProp]) {
            return ascending ? -1 : 1;
          } else {
            return 0;
          }
        });

        if (sortFalsyValuesProp) {
          if (ascending) {
            sortedArray = [
              ...sortedArray.filter((elem) => ascending === elem[sortByProp]),
              ...this.sortArray(
                sortedArray.filter((elem) => ascending !== elem[sortByProp]),
                sortFalsyValuesProp
              ),
            ];
          }
        }
        break;
      case 'string':
        sortedArray = array.sort((a, b) => {
          return ascending ? a[sortByProp].charCodeAt(0) - b[sortByProp].charCodeAt(0) : b[sortByProp].charCodeAt(0) - a[sortByProp].charCodeAt(0);
        });
        break;
      case 'number':
        sortedArray = array.sort((a, b) => {
          return ascending ? a[sortByProp] - b[sortByProp] : b[sortByProp] - a[sortByProp];
        });
        break;
    }
    return sortedArray;
  }

  public static hexToPickerColor(hex): Color {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      };
      return new Color(rgb.r, rgb.g, rgb.b);
    }
    return null;
  }

  public static getThousandsSeparator(): string {
    return environment.name === EnvironmentName.SINNGEBER ? '.' : ',';
  }

  public static getDecimalSeparator(): string {
    return environment.name === EnvironmentName.SINNGEBER ? ',' : '.';
  }
}
