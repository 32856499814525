import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import StorageHelper from '../helpers/Storage.helper';
import { tap } from 'rxjs/operators';
import { TokenData } from '../models/Auth';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // prettier-ignore
  constructor(
    private http: HttpClient
  ) {
  }

  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', encodeURIComponent(email.trim()))
      .set('password', encodeURIComponent(password.trim()))
      .set('grant_type', 'password')
      // .set('scope', 'message:read');
      .set('scope', 'web');

    return this.http.post(`${environment.authServer}/oauth/token`, body.toString(), { headers });
  }

  refreshToken() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', StorageHelper.getUsername())
      .set('refresh_token', StorageHelper.getRefreshToken())
      .set('grant_type', 'refresh_token')
      .set('scope', 'web');

    return this.http.post(`${environment.authServer}/oauth/token`, body.toString(), { headers }).pipe(
      tap((response: TokenData) => {
        StorageHelper.saveTokensAndUsername(response.access_token, response.refresh_token, StorageHelper.getUsername());
      })
    );
  }

  forgotPassword(email) {
    return this.http.post(`${environment.authServer}/forgot-password`, { email });
  }

  resetPassword(token, newPassword) {
    return this.http.post(`${environment.authServer}/reset-password`, { token, newPassword });
  }

  changePassword(body) {
    return this.http.post(`${environment.authServer}/change-password`, body);
  }

  confirmEmailUpdate(token: string) {
    return this.http.get(`${environment.authServer}/confirm-email-update/${token}`);
  }
}
