import { Injectable } from '@angular/core';
import { GlobalConfig, GlobalConfigResponse } from '../models/GlobalConfig';
import { catchError, Observable, of, tap } from 'rxjs';
import { ResponseObject } from '../models/GenericObject';
import StorageHelper from '../helpers/Storage.helper';
import { GeneralService } from '../api/general.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  private _globalConfig: GlobalConfig;

  constructor(private generalService: GeneralService) {}

  public initializeGlobalConfig(): Observable<any> {
    return this.generalService.getGlobalSettings().pipe(
      tap((response: ResponseObject<GlobalConfigResponse>) => {
        this._globalConfig = new GlobalConfig(response.response);
        StorageHelper.saveAppConfig(JSON.stringify(this.globalConfig));
      }),
      catchError(() => {
        console.error('Something went wrong when initializing global configuration.');
        this._globalConfig = StorageHelper.getAppConfig() ? JSON.parse(StorageHelper.getAppConfig()) : new GlobalConfig();
        return of(null);
      })
    );
  }

  get globalConfig(): GlobalConfig {
    return this._globalConfig;
  }
}
