import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalConfigService } from '../services/global-config.service';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router, private globalConfigSvc: GlobalConfigService) {}

  initialize() {
    this.onRouteChange();

    try {
      const trackingCode = this.globalConfigSvc.globalConfig.googleAnalyticsTrackingCode;
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${trackingCode}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${trackingCode}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  private onRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const trackingCode = this.globalConfigSvc.globalConfig.googleAnalyticsTrackingCode;
        gtag('config', `${trackingCode}`, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  // use gtag.js to send Google Analytics Events
  public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
    gtag('event', action, {
      ...(eventCategory && { event_category: eventCategory }),
      ...(eventLabel && { event_label: eventLabel }),
      ...(value && { value: value }),
    });
  }
}
